import { Form } from "@quillforms/renderer-core";
import "@quillforms/renderer-core/build-style/style.css";
import { registerCoreBlocks } from "@quillforms/react-renderer-utils";
import "./mood";
import "./styles.css";
import { createClient } from "@supabase/supabase-js";
const queryParameters = new URLSearchParams(window.location.search);
const appointment_id = +queryParameters.get("appointment_id");
// Create a single supabase client for interacting with your database
const supabase = createClient(
  "https://lvykkopdvnxbqupaivhe.supabase.co",
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imx2eWtrb3Bkdm54YnF1cGFpdmhlIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MDg5ODMzMjIsImV4cCI6MjAyNDU1OTMyMn0.7Y7CC_FwB58ETbhA6jDszC70oxTm0O10ANg8O8vge4o"
);
registerCoreBlocks();

const App = () => {
  return (
    <div style={{ width: "100%", height: "100vh" }}>
      <Form
        formId="1"
        formObj={{
          messages: {
            "label.button.ok": "Suivant", // Default: 'Ok'
            "label.hintText.enter": "appuyez sur <strong>Entrer ↵</strong>", // Default: "",
            "label.hintText.multipleSelection":
              "Choisissez autant que nécessaire", // Default: "Choose as many as you like"
            "block.dropdown.placeholder": "Sélectionnez une option", // Default: "Type or select an option
            "block.dropdown.noSuggestions": "Aucune suggestion", // Default: "No Suggestions!"
            "block.shortText.placeholder": "Votre réponse", // Default: "Type your answer here"
            "block.longText.placeholder": "Votre réponse", // Default: "Type your answer here"
            "block.longText.hint":
              "<strong>Shift ⇧ + Entrer ↵</strong> pour créer un saut de ligne", // Default: "<strong>Shift ⇧ + Enter ↵</strong> to make a line break"
            "block.number.placeholder": "Votre réponse", // Default: "Type your answer here"
            "block.email.placeholder": "Votre e-mail", // Default: "Type your email here"
            "block.defaultThankYouScreen.label":
              "Merci, Votre réponse a été enregistrée.", // Default: "Thanks for filling this in.\n\n We will contact you soon"
            "label.hintText.key": "Touche", // Default: "Key"
            "label.progress.percent": "{{progress:percent}}% complet", // Default: "Questionnaire quotidien {{progress:percent}}% complet"
            "label.errorAlert.required": "Champs obligatoire", // Default: "This field is required!"
            "label.errorAlert.date": "Date invalide", // Default: "Invalid date!"
            "label.errorAlert.number": "Nombres uniquement", // Default: "Numbers only!"
            "label.errorAlert.selectionRequired":
              "Sélectionnez au moins une option", // Default: "Please make at least one selection!"
            "label.errorAlert.email": "E-mail invalide", // Default: "Invalid email!"
            "label.errorAlert.url": "URL invalide", // Default: "Invalid url!"
            "label.errorAlert.range":
              "Merci de choisir un nombre entre {{attribute:min}} et {{attribute:max}}", // Default: "Please enter a number between {{attribute:min}} and {{attribute:max}}"
            "label.errorAlert.minNum":
              "Merci d'entrer un nombre supérieur à {{attribute:min}}", // Default: "Please enter a number greater than {{attribute:min}}"
            "label.errorAlert.maxNum":
              "Merci d'entrer un nombre inférieur à {{attribute:max}}", // Default: "Please enter a number lower than {{attribute:max}}"
            "label.errorAlert.maxCharacters":
              "Nombre maximum de caractères atteint", // Default: "Maximum characters reached!"
            "label.submitBtn": "Envoyer mes réponses", // Default: "Submit"
          },
          blocks: [
            {
              name: "mood",
              id: "are_you_ok",
              attributes: {
                label: "Comment allez-vous depuis la dernière fois?",
                leftValue: "Pas bien",
                rightValue: "Très bien",
              },
            },
            {
              name: "long-text",
              id: "long_text_to_share",
              attributes: {
                label:
                  "Est-ce que vous avez quelque chose de spécial à communiquer au médecin?",
              },
            },
          ],
        }}
        onSubmit={(data, { completeForm, setIsSubmitting }) => {
          setTimeout(async () => {
            console.log(data.answers);
            await supabase
              .from("appointments")
              .update({
                preconsultation_form_filled: true,
                pre_consultation_feeling_score: data.answers.are_you_ok.value,
                pre_consultation_patient_comments:
                  data.answers.long_text_to_share.value,
              })
              .eq("id", appointment_id);
            setIsSubmitting(false);
            completeForm();
          }, 500);
        }}
      />
    </div>
  );
};
export default App;
